import {formValidation, plugins} from "./lib/formvalidation_1_8_1/es6/index";

import rteInit from './jq-rte-summernote-init';
import Bootstrap from "../../src-admin/lib/formvalidation_1.3.0/es6/plugins/Bootstrap";

import * as Ladda from 'ladda';
import _ from "underscore";
import './lib/jquery.form';

export function dsContactsFormSizeTextarea(){

    $('.contacts-form').each(function () {
       if($(this).find('textarea.size-to-col').length > 0)
       {
           var $ta = $(this).find('textarea.size-to-col');
           var $r = $ta.closest('form');
           var $refCol = $r.find('.measure-col');
           var $refField = $r.find('.measure-input');


           var measureColPosition = $refCol.offset().top;
           var measureFieldPosition = $refField.offset().top;
           var blockHeight = measureFieldPosition - measureColPosition + $refField.outerHeight();

           $ta.css('height', blockHeight + 'px');

       }
    });
}

export function dsInitContactsFormValidation($form){

    var form = $form.get(0);

    var fields = {
        i_anfrage: {
            validators: {
                notEmpty: {
                    message: 'Bitte wählen Sie eine Art der Anfrage aus.'
                },
                srv: {}
            }
        },
        i_name: {
            validators: {
                notEmpty: {
                    message: 'Bitte geben Sie Ihren Namen ein.'
                },
                srv: {}
            }
        },
        i_email: {
            validators: {
                notEmpty: {
                    message: 'Bitte geben Sie Ihre E-Mail-Adresse ein.'
                },
                emailAddress: {
                    message: 'Die eingegebene E-Mail-Adresse ist ungültig.'
                },
                srv: {}
            }
        },
        i_message: {
            validators: {
                notEmpty: {
                    message: 'Bitte geben Sie eine Nachricht ein.'
                },
                srv: {}
            }
        },
        cb_dp: {
            validators: {
                notEmpty: {
                    message: 'Zum Versand dieser Nachricht müssen Sie der Verarbeitung der eingegebenen Daten zustimmen.'
                },
                srv: {}
            }
        },
        i_superfield: {
            enabled: false,
            validators: {
                stringLength: {
                    max: 0,
                    min: 0,
                    message: 'Dieses Feld darf keinen Inhalt haben!'
                }
            }
        }
    };

    var fv = formValidation(
            form,
            {
                fields: fields,
                plugins: {
                    bootstrap: new Bootstrap(),
                    submitButton: new plugins.SubmitButton(),
                    sequence: new plugins.Sequence({
                        enabled: true,
                    }),
                    declarative: new plugins.Declarative({
                        html5Input: false
                    }),
                    trigger: new plugins.Trigger({
                        event: {
                            nlemail: 'blur change',
                            cb_dp_nl: 'change',
                        },
                        threshold: 3,
                        delay: 0,
                    }),
                    excluded: new plugins.Excluded({
                        excluded: function(field, element, elements) {

                            // Check if field is hidden:
                            if($(element).is(':hidden'))
                            {
                                return true;
                            }

                            // Check if field is empty:
                            if($(element).data('fv-excluded-if-empty') === true)
                            {
                                // Enable validation if field has value:
                                if($(element).val().length > 0)
                                {
                                    return false;
                                }
                                else
                                {
                                    return true;
                                }
                            }
                            else
                            {
                                // Always required.
                                return false;
                            }
                        }
                    }),
                },
            })
        .on('core.form.valid', function() {

            var l = Ladda.create( $form.find('.btn-submit.ladda-button').get(0) );
            l.start();
            $form.data('myLadda', l);

            // Submit form:
            $form.ajaxSubmit({
                url: $form.attr('action'),
                dataType: 'json',
                method: 'post',
                success: function(responseData, statusText, xhr, $form) {

                    // TODO: Improve whole success handling!

                    if(responseData.result == 'ok')
                    {
                        // Redirect to thankyou article?
                        location.href = responseData.url;


                    }
                    else {

                        // Get server error message:
                        if (typeof responseData.errormessage !== "undefined")
                        {
                            // Stop ladda;
                            l.stop();

                            // Get server error message:
                            var errorMessage = responseData.errormessage;

                            // Show message:
                            $form.find('.main-form-error-out').html(errorMessage);

                        }
                        else if (typeof responseData.fielderrors !== "undefined")
                        {
                            // Stop ladda;
                            l.stop();

                            // Serverseitig erkannte Feldfehler bearbeiten (Fehler anzeigen):
                            for(var i in responseData.fielderrors)
                            {

                                if(responseData.fielderrors[i].length > 0)
                                {
                                    fv
                                        // Update the message option
                                        .updateValidatorOption(
                                            i, 'srv', 'message', responseData.fielderrors[i]
                                        )
                                        // Set the field as invalid
                                        .updateFieldStatus(i, 'Invalid', 'srv');

                                }
                            }

                        }
                        else
                        {
                            l.stop();

                            // Get server error message:
                            var errorMessage = $form.find('.main-form-error-out').data('message-servererror');

                            // Show message:
                            $form.find('.main-form-error-out').html(errorMessage);

                        }

                    }


                },
                error: function(xhr, status, error, $form)
                {
                    // Stop ladda;
                    l.stop();

                    // Get server error message:
                    var errorMessage = $form.find('.main-form-error-out').data('message-servererror');

                    // Show message:
                    $form.find('.main-form-error-out').addTimedMessage('danger', errorMessage);

                }
            });

        }).on('core.form.invalid', function() {
            $(window).trigger('resize.dsContactsFormSizeTextarea');
            setTimeout(dsContactsFormSizeTextarea, 10);
        }).on('core.form.validating', function() {
            $(window).trigger('resize.dsContactsFormSizeTextarea');
            setTimeout(dsContactsFormSizeTextarea, 10);
        }).on('core.form.validated', function() {
            $(window).trigger('resize.dsContactsFormSizeTextarea');
            setTimeout(dsContactsFormSizeTextarea, 10);
        }).on('core.form.valid', function() {
            $(window).trigger('resize.dsContactsFormSizeTextarea');
            setTimeout(dsContactsFormSizeTextarea, 10);
        });

    $form.data('form-fv', fv);

}

