import "./import-jquery";
import '@popperjs/core';
import './import-bootstrap-global'; // required for bootstrap-select etc.
import 'bootstrap-select';
import 'jquery-focuspoint';
import 'jquery.typewatch';
import 'jquery-autogrow-textarea';
import 'labelholder/dist/labelholder';
import 'bxslider/dist/jquery.bxslider';
import ScrollReveal from "scrollreveal";
import Cookies from "js-cookie";
import 'jquery-sticky';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import 'moment-timezone';
import  'moment/locale/de';
import deparam from "jquery-deparam";
import _ from "underscore";
import Plyr from "plyr";
import './lib/jquery.pause.min';
import './jq-timed-message';
import {dsContactsFormSizeTextarea, dsInitContactsFormValidation } from './jq-contactform';
import fancybox from '@fancyapps/fancybox';
import zxcvbn from 'zxcvbn/dist/zxcvbn';
import BootstrapDialog from 'bootstrap4-dialog';

// Export underscore to global scope:
var FE = new FECore();
FE.init();
export default (window._ = _, window.BootstrapDialog = BootstrapDialog, window.moment = moment, window.uuidv4 = uuidv4, window.FE = FE, window.loadJsCssFile = loadJsCssFile);

'./lib/formvalidation_1_8_1/es6';
import ds81_initAutovalidatorForm from './jq-autovalidator-form_import';
import {formValidation, plugins} from "./lib/formvalidation_1_8_1/es6";
import Bootstrap from "../../src-admin/lib/formvalidation_1.3.0/es6/plugins/Bootstrap";
import * as Ladda from "ladda";
import jquery from "jquery";

// set variables
var xs;
var sm;
var md;
var lg;
var xl;
var breakpoint;

/**
 * forEach implementation for Objects/NodeLists/Arrays, automatic type loops and context options
 *
 * @private
 * @author Todd Motto
 * @link https://github.com/toddmotto/foreach
 * @param {Array|Object|NodeList} collection - Collection of items to iterate, could be an Array, Object or NodeList
 * @callback requestCallback      callback   - Callback function for each iteration.
 * @param {Array|Object|NodeList} scope=null - Object/NodeList/Array that forEach is iterating over, to use as the this value when executing callback.
 * @returns {}
 */
var lm_forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};


// Checks if the span is set to display lock via CSS
function checkIfBlock (target) {
    var target = $(target).css('display') == 'block';
    return target;
}

// function to check the sizes
function checkSize (){
    // Set some variables to use with the if checks below

    xs = checkIfBlock('.breakpoint-check .xs');
    sm = checkIfBlock('.breakpoint-check .sm');
    md = checkIfBlock('.breakpoint-check .md');
    lg = checkIfBlock('.breakpoint-check .lg');
    xl = checkIfBlock('.breakpoint-check .xl');

// add the breakpoint to the console
// console.clear();
    if( xs == true) {
        breakpoint = "xs - <576px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "xs" );
    }

    if( sm == true) {
        breakpoint = "sm - ≥576px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "sm" );
    }

    if( md == true) {
        breakpoint = "md - ≥768px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "md" );
    }

    if( lg == true) {
        breakpoint = "lg - ≥992px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "lg" );
    }

    if( xl == true) {
        breakpoint = "xl - ≥1200px";
        // console.log(breakpoint);
        $("body").removeClass('xs sm md lg xl').addClass( "xl" );
    }

}
// end check size

$(document).ready(function(){
    // Add some invisible elements with Bootstrap CSS visibile utility classes
    $( "body" ).append( "<div style='display:none;' class='breakpoint-check'><span class='xs d-block d-sm-inline'></span><span class='sm d-sm-block d-md-inline'></span><span class='md d-md-block d-lg-inline'></span><span class='lg d-lg-block d-xl-inline'></span><span class='xl d-xl-block'></span></div>" );
    checkSize();
    adjustBreadCrumbScroll();
});


// Reload demo on  window resize
$( window ).resize( function(){
    checkSize();
    adjustBreadCrumbScroll();
});


function winDim(){
    // compute window width:
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        winWidth = w.innerWidth || e.clientWidth || g.clientWidth,
        winHeight = w.innerHeight|| e.clientHeight|| g.clientHeight;

    return {width: winWidth, height: winHeight };
}


function adjustBreadCrumbScroll(){
    $('.breadcrumb ul').animate({
        scrollLeft: 9999
    }, 500);
}

$(function () {

    // Sticky header:
    $(window).on('resize load', ds81_header_sticky);
    ds81_header_sticky();

    // Hash detect:
    window.addEventListener('hashchange', ds81_handleHash);
    $(window).on('load', ds81_handleHash);
    ds81_handleHash();

    // Load postInit scripts:
    if(typeof loadJsCssFileList !== "undefined")
    {
        for(var i in loadJsCssFileList)
        {
            var task = loadJsCssFileList[i];
            loadJsCssFile(task[0], task[1], task[2]);
        }
    }

    // Min height to have footer at bottom:
    $(window).on('resize load', ds81_set_min_fill_height);
    // Safari bugfix: Reposition image on load:
    $("img").on('load', function () {
        $(this).off('load');
        ds81_set_min_fill_height();
    }).each(function () {
        if (this.complete) {
            $(this).off('load');
            ds81_set_min_fill_height();
        }
    });
    ds81_set_min_fill_height();

    // Sticky sidebar:
    $(window).on('resize load', function (){
        if(isWithinBreakpoint('sm') || isWithinBreakpoint('xs'))
        {
            $('aside').unstick();
        }
        else
        {
            var bottomSpaceAdd = 0;
            if($('.bottom-sponsor-block').length > 0)
            {
                bottomSpaceAdd = $('.bottom-sponsor-block').outerHeight(true);
            }
            $('aside').sticky({
                topSpacing: $('header').outerHeight(true) + 10,
                bottomSpacing: $('footer').outerHeight() + 60 + bottomSpaceAdd,
                zIndex: 10,
                getWidthFrom: $('.aside-sticky-width-ref').first()
            });
        }
    });

    // Scroll reveals:
    ScrollReveal().reveal('.flip-card', {interval: 100, scale: .1, distance: '100%'});
    // Reveal teasers only when page is not scrolled much.
    // Prevents teasers form not showing when page is reloaded with larger scroll values.
    if($('body,html').scrollTop() < 30){
        ScrollReveal().reveal('.teaser-item', {
            interval: 300, scale: .1, viewOffset: {
                bottom: 90,
            }
        });
    }

    // Set hero text size on load and on resize:
    $(window).on('resize.ds81_hero_text_resize', ds81_hero_text_resize);
    $(window).on('load.ds81_hero_text_resize', ds81_hero_text_resize);
    ds81_hero_text_resize();

    // Safari bugfix: Reposition image on load:
    $(".header-visual img").on('load', function () {
        $(".header-visual img").off('load');
        $(window).trigger('scroll.ds81_header_dynamics');
    }).each(function () {
        if (this.complete) {
            $(".header-visual img").off('load');
            $(window).trigger('scroll.ds81_header_dynamics');
        }
    });

    // Set focuspoint on opening accordions:
    $('.accordion').on('shown.bs.collapse', function (){
        $(this).find('.focuspoint').focusPoint();
    })

    // Set image heights:
    $(window).on('resize.ds81setImageHeights', dsSetImageHeights);
    dsSetImageHeights();

    // Teaser slider:
    $('.teaser-slider').each(function (){
        let teaserSlider = $(this).find('.teaser-slider-items').bxSlider({
            autoHover: true,
            auto: true,
            speed: 800,
            pause: 6000,
            controls: false,
            pager: false,
            onSlideBefore: function ($slideElement, oldIndex, newIndex){
                let $root = $($slideElement).closest('.container');
                let $iconContainer = $root.find('.teaser-pager');
                $iconContainer.find('.circle-icon').removeClass('active');
                $iconContainer.find('.circle-icon').eq(newIndex).addClass('active');
            },
            onSliderLoad: function (currentIndex){
                let $root = $(this).closest('.container');
                let $iconContainer = $root.find('.teaser-pager');
                $iconContainer.find('.circle-icon').removeClass('active');
                $iconContainer.find('.circle-icon').eq(currentIndex).addClass('active');
            }
        });

        $(this).find('.teaser-pager .circle-icon').on('click.setSlideIndex', function (){
            teaserSlider.stopAuto();
            teaserSlider.startAuto();
            teaserSlider.goToSlide($(this).index());
        });

    });

    // Mobile nav open close:
    $('.oc-nav-trigger').on('click.nav-main-toggle',function () {
        $(this).find('.hamburger').toggleClass('is-active');
        if($(this).find('.hamburger').hasClass('is-active'))
        {
            $('html').addClass('nav-on');

            let itemCount = $('.nav-items .items-col').length;
            $('.nav-items .items-col').each(function (){
                $(this).animate({
                    opacity: 0
                }, 0, function (){
                    $(this).delay((itemCount - $(this).index()) * 50).animate({
                        opacity: 1,
                    }, 200);
                });
            });


        }
        else
        {
            $('html').removeClass('nav-on');

            $('.nav-items .items-col').each(function (){
                $(this).delay($(this).index() * 40).animate({
                    opacity: 0,
                }, 60);
            });
        }
    });
    $('.nav-backdrop-layer').on('click.nav-main-close-via-layer', function () {
        $('.oc-nav-trigger').first().trigger('click');
    });

    // $('.oc-nav-trigger').first().trigger('click');

    var subLevelLists = document.querySelectorAll(".mona-nav-list-main > li > ul");
    if (subLevelLists.length > 0) {
        lm_forEach(subLevelLists, function(subLevelListItem) {
            $(subLevelListItem).slideUp(0);
        });
    }

    // -- Mobile navigation plus button slider -------------------
    var subLevelTriggers = document.querySelectorAll(".mona-nav-list-main .sublevel-trigger");
    if (subLevelTriggers.length > 0) {
        lm_forEach(subLevelTriggers, function(subLevelTrigger) {
            if($(subLevelTrigger).hasClass('open'))
            {
                $(subLevelTrigger).closest('li').find('> ul').slideDown(199);
            }
            subLevelTrigger.addEventListener("click", function(e) {

                subLevelTrigger.classList.toggle("open");

                if($(subLevelTrigger).hasClass('open'))
                {
                    $(e.currentTarget).closest('ul').find('.sublevel-trigger.open').each(function (){
                        if($(this).closest('li').index() != $(e.currentTarget).closest('li').index())
                        {
                            $(this).removeClass('open');
                            $(this).closest('li').find('> ul').slideUp(199);
                        }
                    })

                    $('.mona-nav-list-main').animate({
                        scrollTop: $(e.currentTarget).closest('li').position().top - parseInt($('.mona-nav-list-main').parent().css('paddingTop')) + $('.mona-nav-list-main').scrollTop()
                    }, 100);
                    $(subLevelTrigger).closest('li').find('> ul').slideDown(199);
                }
                else
                {
                    $(subLevelTrigger).closest('li').find('> ul').slideUp(199);
                }

            }, false);
        });
    }
    // -- END mobile navigation plus button slider -------------------

    // Activate open element:
    $('.mona-nav-list-main > li.active').find('> .sublevel-trigger').trigger('click');

    // Link expand lists:
    $('.btn-more-list-expand').each(function () {
        var myId = $(this).data('more-list-target');
        var $myList = $('[data-more-list-id="' + myId + '"]');
        $myList.each(function () {
            var listLength = parseInt($(this).data('more-list-length'));
            $(this).find('.more-item:gt(' + (listLength - 1) + ')').hide();
        });

        $(this).on('click.expandMoreListAction', function () {
            var myId = $(this).data('more-list-target');
            var $myList = $('[data-more-list-id="' + myId + '"]');
            $myList.each(function () {
                var listLength = parseInt($(this).data('more-list-length'));
                var currentLength = $(this).find('.more-item:visible').length;
                var newLength = currentLength + listLength;
                $(this).find('.more-item:lt(' + newLength + ')').show();

                var $firstNewItem = $(this).find('.more-item:eq(' + currentLength + ')');

                var scrollDist = Math.floor($firstNewItem.offset().top - 50);

                var harmonizedScrollDuration = parseInt(Math.floor(scrollDist * .5));
                $('html, body').animate({ scrollTop: scrollDist}, harmonizedScrollDuration);

                if($(this).find('.more-item:hidden').length < 1)
                {
                    $('.btn-more-list-expand[data-more-list-target="' + $(this).data('more-list-id') + '"]').closest('.events-filter-more-block').hide();
                }

            });
        });
    });

    $('.videocontainer').each(function(){
        $(this).find('.videobox').each(function () {
            ds81_init_videobox($(this));
        });
    });

    // Handle changes in consent selection:
    $(window).on('dscookiebar_consentchange', dscookiebar_handle_consent_change);
    dscookiebar_handle_consent_change();


    // Check and handle cookie bar show/hide:
    if($('#dscookiebar_consent').length > 0)
    {
        // Consent version id:
        var cookieVersion = $('#dscookiebar_consent').first().data('version');

        // Cookie path:
        var cookiePath = typeof path_root !== "undefined" ? path_root : '';

        // Read cookie:
        if(typeof Cookies.get('dscookieconsent_version', {path: cookiePath}) === "undefined" || Cookies.get('dscookieconsent_version', {path: cookiePath}) != cookieVersion)
        {
            $('#dscookiebar_consent').each(function () {

                // Prevent form submit:
                $(this).find('form').submit(function(e){
                    e.preventDefault();
                });

                // Link select checkboxes:
                $(this).find('input[type="checkbox"]').each(function (){
                    if(typeof $(this).attr('checked') !== "undefined"){
                        $(this).prop('checked', true);
                    }
                });
                $(this).find('input[type="checkbox"]').on('change', function () {
                    // Transfer status to possibly present cookie bar:
                    $('#dscookiebar_onpage_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));
                });

                $(this).removeClass('d-none');
                $(this).find('.content-select-consent').addClass('d-none');
                $(this).find('.content-default').removeClass('d-none');

                $(this).find('.btn-accept-all').on('click.dscookiebaracceptall', dscookiebar_accept_all_handler);
                $(this).find('.btn-make-choice').on('click.dscookiebarmakechoice', dscookiebar_show_select_consent_content);
                $(this).find('.btn-cancel-consent-select').on('click.dscookiebarcancel', dscookiebar_show_default_content);
                $(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);
            });

        }

    }

    // Link onpage component:
    if($('#dscookiebar_onpage_consent').length > 0)
    {
        $('#dscookiebar_onpage_consent').each(function () {

            // Prevent form submit:
            $(this).submit(function(e){
                e.preventDefault();
            });

            // Link select checkboxes:
            $(this).find('input[type="checkbox"]').each(function (){
                if(typeof $(this).attr('checked') !== "undefined"){
                    $(this).prop('checked', true);
                }
            });

            // Process checkboxes:
            $(this).find('input[type="checkbox"]').each(function () {

                // Set initial state:
                if(dscookiebar_has_consent($(this).attr('name')))
                {
                    $(this).prop('checked', true);
                }


                // Link checkbox to transfer state to cookie bar:
                $(this).on('change', function () {

                    // Transfer status to possibly present cookie bar:
                    $('#dscookiebar_consent input[type="checkbox"][name="' + $(this).attr('name') + '"]').prop('checked', $(this).prop('checked'));

                    // Check status:
                    dscookiebar_onpage_check_status();

                });

            });

            // Link save button:
            $(this).find('.btn-save-consent-settings').on('click.dscookiebarsaveconsent', dscookiebar_save_consent);

        });

        // Listen to change events to update onpage status:
        $(window).on('dscookiebar_consentchange', function () {

            // Check status:
            dscookiebar_onpage_check_status();

        });

        // Initial status check:
        dscookiebar_onpage_check_status();

    }

    $('.page-slider-default .slider-wrapper').each(function(){

        var pauseValue = typeof $(this).data('bx-pause') !== "undefined" && parseInt($(this).data('bx-pause')) > 0 ? parseInt($(this).data('bx-pause')) : 5000;
        var speedValue = typeof $(this).data('bx-speed') !== "undefined" && parseInt($(this).data('bx-speed')) > 0 ? parseInt($(this).data('bx-speed')) : 500;
        var modeValue = typeof $(this).data('bx-mode') !== "undefined" && $(this).data('bx-mode').length > 0 ? $(this).data('bx-mode') : 'horizontal';

        if($(this).find('.slider-element figcaption').length > 0)
        {
            $(this).find('.slider-element').each(function (){
                if($(this).find('figcaption').length < 1)
                {
                    $(this).find('figure').append('<figcaption style="opacity: 0;"><p>&nbsp;</p></figcaption>');
                }
            });
        }

        $(this).bxSlider({
            controls: false,
            pager: true,
            pagerType: 'default',
            pagerShortSeparator: '/',
            auto: true,
            adaptiveHeight: true,
            pause: pauseValue,
            speed: speedValue,
            mode: modeValue,
            preloadImages: 'all',
            onSliderLoad: function (currentIndex){
                var $elem = $(this).find('.slider-element:visible').eq(currentIndex - 1);
                var capHeight = $elem.find('.img-wrapper').outerHeight(true) + 10;
                $elem.closest('.bx-wrapper').find('.bx-pager').css('top', capHeight + 'px');
            },
            onSlideBefore: function ($elem, oldIndex, newIndex)
            {
                setTimeout(function (){
                    var capHeight = $elem.find('.img-wrapper').outerHeight(true) + 10;
                    $elem.closest('.bx-wrapper').find('.bx-pager').css('top', capHeight + 'px');

                }, 10);

            }
        });
    });

    // Autovalidator Form init:
    $('form.autovalidator-form').each(function () {
        ds81_initAutovalidatorForm($(this));
    });


    // Initialize square gallery:
    if($('.square-gallery').length > 0)
    {
        $(window).on('resize.ds81_square_gallery_sizer', ds81_square_gallery_sizer);
        ds81_square_gallery_sizer();
    }

    // Enable labelholder form input placeholders:
    $('.labelholder').labelholder();
    $('input[type="text"],input[type="password"]').each(function () {
        if($(this).parent().hasClass('labelholder'))
        {
            $(this).on('focus', function () {
                $(this).parent().addClass('labelholder-focus');
            });
            $(this).on('blur', function () {
                $(this).parent().removeClass('labelholder-focus');
            });
        }
    });

    // Default frontend selectpicker:
    $('select.fe_selectpicker, select.cms-selectpicker-default').selectpicker({
        styleBase: 'form-control',
        style: ''
    }).on('loaded.bs.select', function () {
        var ph = $(this).attr('data-onselect-placeholder');
        var initClass = 'has-labelholder';
        if(typeof $(this).val() === "string" && $(this).val() == "" || typeof $(this).val() === "object" && $(this).val().length < 1)
        {
            initClass = 'has-labelholder-disabled';
        }
        $(this).parent().find('.dropdown-toggle').attr('data-onselect-placeholder', ph).addClass(initClass);
    }).on('changed.bs.select', function () {
        if(typeof $(this).val() === "string" && $(this).val() == "" || typeof $(this).val() === "object" && $(this).val().length < 1)
        {
            $(this).closest('.dropdown.bootstrap-select').find('button.dropdown-toggle').addClass('has-labelholder-disabled');
            $(this).closest('.dropdown.bootstrap-select').find('button.dropdown-toggle').removeClass('has-labelholder');
        }
        else
        {
            $(this).closest('.dropdown.bootstrap-select').find('button.dropdown-toggle').addClass('has-labelholder');
            $(this).closest('.dropdown.bootstrap-select').find('button.dropdown-toggle').removeClass('has-labelholder-disabled');
        }
    });

    // Selectpicker with url select task:
    $('select.url-select').selectpicker({
        styleBase: 'form-control',
        style: ''
    }).on('changed.bs.select', function (e) {
        location.href = $(e.currentTarget).val();
    });


    ds81_init_contacts_form();

    $(window).on('ds81.lightbox.lateinit', ds81_lightbox_late_init);

    $(window).on('ds81.exam.formload', ds81_exam_form_init);
    if(typeof employee_uid !== "undefined")
    {
        // console.log('listen', 'ds81.employee.' + employee_uid + '.exam.statusupdate');
        $(window).on('ds81.employee.' + employee_uid + '.exam.statusupdate', ds81_exam_reload_status);
    }


    // Check for and initialize push connection:
    if(typeof pushconnection_employee !== "undefined")
    {
        // Parse string data to JSON object:
        var pushConnectionData = JSON.parse(pushconnection_employee);

        localStorage.debug = '-';

        loadJsCssFile(pushConnectionData.host + '/socket.io/socket.io.js', 'js', function () {

            // Initialize socket:
            var socket = io(pushConnectionData.host + '/' + pushConnectionData.namespace);

            // Handle "event" type request: Trigger window event.
            socket.on('event', function(msg) {
                if(typeof msg === "object")
                {
                    $(window).trigger(msg.event, msg.data);
                }
                else
                {
                    $(window).trigger(msg);
                }
            });

        });

    }

});


function ds81_init_contacts_form()
{
    // Init form validation on contacts page:
    if($('.contacts-form').length > 0)
    {



        $('.contacts-form').each(function () {

            var $form = $(this);

            if(!$form.hasClass('ds-form-contact-init-done'))
            {
                $form.addClass('ds-form-contact-init-done');

                dsInitContactsFormValidation($form);

                $form.find('.ds-selectpicker-cf').on('loaded.bs.select', function () {
                    var ph = $(this).attr('data-onselect-placeholder');
                    $(this).parent().find('.btn.dropdown-toggle').attr('data-onselect-placeholder', ph);
                }).on('changed.bs.select', function () {
                    setTimeout(dsContactsFormSizeTextarea, 10);
                    var fv = $(this).closest('form').data('form-fv');
                    fv.revalidateField($(this).attr('name'));
                });


                // Sizer:
                if($form.find('textarea.size-to-col').length > 0)
                {
                    $(window).on('resize.dsContactsFormSizeTextarea', dsContactsFormSizeTextarea);
                    dsContactsFormSizeTextarea();

                    $form.find('input').on('input', dsContactsFormSizeTextarea);
                    $form.find('textarea').on('input', dsContactsFormSizeTextarea);
                    $form.find('select').on('input', dsContactsFormSizeTextarea);

                }
            }

        });
    }

}


// File loader:
export var _us = _;
function loadJsCssFile(filename, filetype, callback){
    if (filetype=="js"){ //if filename is a external JavaScript file
        var fileref=document.createElement('script')
        fileref.setAttribute("type","text/javascript")
        fileref.setAttribute("src", filename)
    }
    else if (filetype=="css"){ //if filename is an external CSS file
        var fileref=document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
    }

    if(typeof callback === "function")
    {
        fileref.onload = callback;
    }

    if (typeof fileref!="undefined")
        document.getElementsByTagName("head")[0].appendChild(fileref)
}


// Exams: Reload status fragment
function ds81_exam_reload_status(){
    $('.exam-status-info').each(function () {

       $(this).load(location.href + ' #' + $(this).attr('id'));

    });
}

function ds81_lightbox_late_init()
{

    // Default frontend selectpicker:
    $('select.fe_selectpicker, select.cms-selectpicker-default').selectpicker({
        styleBase: 'form-control',
        style: ''
    }).on('loaded.bs.select', function () {
        var ph = $(this).attr('data-onselect-placeholder');
        $(this).parent().find('.dropdown-toggle').attr('data-onselect-placeholder', ph).addClass('has-labelholder');
    });

    // Selectpicker with url select task:
    $('select.url-select').selectpicker({
        styleBase: 'form-control',
        style: ''
    }).on('changed.bs.select', function (e) {
        location.href = $(e.currentTarget).val();
    });

    // Autovalidator Form init:
    $('form.autovalidator-form').each(function () {
        ds81_initAutovalidatorForm($(this));
    });

    ds81_init_contacts_form();

    // Contact form topic selefct via lightbox link hash attribute?
    var anchorId = $.fancybox.getInstance().$trigger.data('target-anchor');
    if(anchorId && typeof anchorId == 'string' && anchorId.length > 0)
    {
        setTimeout(function () {
            var $currentContent = $($.fancybox.getInstance().current.$content.get(0));
            var $selectField = $currentContent.find('select[name="i_anfrage"]');
            if($selectField.length > 0)
            {
                var $selectOption = $selectField.find('[data-preselect-id="' + anchorId + '"]');
                if($selectOption.length > 0)
                {
                    $selectField.selectpicker('val', $selectOption.attr('value'));
                }
            }
        }, 10);

    }

}

// Exams: Handle answer option change:
function ds81_exam_form_init(){

    $('.exam-question input').change(function () {

        var answerVal = $(this).val();

        if($(this).attr('type') == 'checkbox')
        {
            // Obtain value list:
            answerVal = [];
            $(this).closest('form').find('input[name="' + $(this).attr('name') + '"]:checked').each(function (){
                answerVal.push($(this).val());
            });
        }

        // Send data:
        var _this = $(this);
        $.ajax(
            $(this).closest('.exam-question').data('report-endpoint'),
            {
                type: 'post',
                data: {
                    answer: answerVal
                },
                dataType: 'json',
                success: function(data, textStatus, jqXHR) { // AJAX was ok

                }

            }
        );
    });

    $('.exam-question textarea').autogrow().typeWatch({
        callback: function (value) {

            $.ajax(
                $(this).closest('.exam-question').data('report-endpoint'),
                {
                    type: 'post',
                    data: {
                        answer: value
                    },
                    dataType: 'json',
                    success: function(data, textStatus, jqXHR) { // AJAX was ok

                    }

                }
            );

        },
        wait: 250,
        highlight: true,
        allowSubmit: false,
        captureLength: 1
    });

    $('.exam-question textarea').trigger('keyup');

    $('.exam-form').each(function () {

        if(!$(this).hasClass('init-done'))
        {
            $(this).addClass('init-done');

            var $form = $(this);
            var form = $form.get(0);

            var fields = {

            };

            var fv = formValidation(
                form,
                {
                    fields: fields,
                    plugins: {
                        bootstrap: new Bootstrap(),
                        submitButton: new plugins.SubmitButton(),
                        sequence: new plugins.Sequence({
                            enabled: true,
                        }),
                        declarative: new plugins.Declarative({
                            html5Input: false
                        }),
                        trigger: new plugins.Trigger({
                            event: {
                                nlemail: 'blur change',
                                cb_dp_nl: 'change',
                            },
                            threshold: 3,
                            delay: 0,
                        }),
                        excluded: new plugins.Excluded({
                            excluded: function(field, element, elements) {

                                // Check if field is hidden:
                                if($(element).is(':hidden'))
                                {
                                    return true;
                                }

                                // Check if field is empty:
                                if($(element).data('fv-excluded-if-empty') === true)
                                {
                                    // Enable validation if field has value:
                                    if($(element).val().length > 0)
                                    {
                                        return false;
                                    }
                                    else
                                    {
                                        return true;
                                    }
                                }
                                else
                                {
                                    // Always required.
                                    return false;
                                }
                            }
                        }),
                    },
                })
                .on('core.form.valid', function() {

                    var l = Ladda.create( $form.find('.btn-submit.ladda-button').get(0) );
                    l.start();
                    $form.data('myLadda', l);

                    // Submit form:
                    $form.ajaxSubmit({
                        url: $form.attr('action'),
                        dataType: 'json',
                        method: 'post',
                        success: function(responseData, statusText, xhr, $form) {

                            // TODO: Improve whole success handling!

                            ds81_exam_reload_status();

                            if(responseData.result == 'ok')
                            {

                                // Get thankyou content:
                                var tplHtml = $.fancybox.getInstance().$trigger.closest('.teaser-content').find('.exam-thankyou-content').html();
                                var tpl = _.template(tplHtml);
                                var tplRendered = tpl({
                                });

                                // Update current fancybox content:
                                var $currentContent = $($.fancybox.getInstance().current.$content.get(0));
                                $currentContent.find('.content-wrap').html(tplRendered)

                                // Set new content:
                                $.fancybox.getInstance().setContent($.fancybox.getInstance().current, $currentContent.get(0));
                                $.fancybox.getInstance().update();


                            }
                            else {

                                // Get server error message:
                                if (typeof responseData.errormessage !== "undefined")
                                {
                                    // Stop ladda;
                                    l.stop();

                                    // Get server error message:
                                    var errorMessage = responseData.errormessage;

                                    // Show message:
                                    $form.find('.main-form-error-out').html(errorMessage);

                                }
                                else if (typeof responseData.fielderrors !== "undefined")
                                {
                                    // Stop ladda;
                                    l.stop();

                                    // Serverseitig erkannte Feldfehler bearbeiten (Fehler anzeigen):
                                    for(var i in responseData.fielderrors)
                                    {

                                        if(responseData.fielderrors[i].length > 0)
                                        {
                                            fv
                                                // Update the message option
                                                .updateValidatorOption(
                                                    i, 'srv', 'message', responseData.fielderrors[i]
                                                )
                                                // Set the field as invalid
                                                .updateFieldStatus(i, 'Invalid', 'srv');

                                        }
                                    }

                                }
                                else
                                {
                                    l.stop();

                                    // Get server error message:
                                    var errorMessage = $form.find('.main-form-error-out').data('message-servererror');

                                    // Show message:
                                    $form.find('.main-form-error-out').html(errorMessage);

                                }

                            }


                        },
                        error: function(xhr, status, error, $form)
                        {
                            // Stop ladda;
                            l.stop();

                            // Get server error message:
                            var errorMessage = $form.find('.main-form-error-out').data('message-servererror');

                            // Show message:
                            $form.find('.main-form-error-out').addTimedMessage('danger', errorMessage);

                        }
                    });

                });

            $form.data('form-fv', fv);


        }

    });


}

function ds81_header_sticky(){
    $('header').sticky({
        topSpacing:1,
        zIndex: 110
    });
}


function ds81_square_gallery_sizer() {
    $('.square-gallery-item').each(function () {
        $(this).find('.square-gallery-item-inner').css('height', '');
        $(this).find('.square-gallery-item-inner').css('height', $(this).width() + 'px');

        $(this).find('.square-gallery-item-inner').each(function () {

            if($(this).hasClass('focuspoint'))
            {
                $(this).data('focusPoint').adjustFocus();
            }

            if(!$(this).hasClass('focuspoint') && !$(this).hasClass('square-bg'))
            {
                $(this).addClass('square-bg');
                var $img = $(this).find('img').first();
                var imgSrc = $img.attr('src');
                var $picture = $img.closest('picture');
                if($picture.length > 0)
                {
                    if($('html').hasClass('webp'))
                    {
                        var $webp = $picture.find('[type="image/webp"]');
                        if($webp.length > 0)
                        {
                            imgSrc = $webp.attr('srcset');
                        }
                    }
                }
                $(this).css('background-image', 'url(' + imgSrc + ')');
                $img.css('visibility', 'hidden');
            }
        });


    });
}


function ds81_handleHash(){

    setTimeout(function (){

        if(window.location.hash.indexOf('=') < 0 && $(window.location.hash).length > 0)
        {
            var $item = $(window.location.hash).first();


            var scrollDist = Math.floor($item.offset().top - $('header').outerHeight(true) - 18);

            var harmonizedScrollDuration = parseInt(Math.floor(scrollDist * .5));
            $('html, body').animate({ scrollTop: scrollDist}, harmonizedScrollDuration);
        }

    }, 120);
}

function ds81_hero_text_resize()
{
    var wMax = 1296;

    $('.hero-image .image-item').each(function (){

        // Reset:
        $(this).css('font-size', '1rem');

        // Set if below max:
        if($(this).outerWidth() < wMax){
            var wPercent = (100 / wMax) * $(this).outerWidth();
            var calcFontSize = (wPercent/100);

            $(this).css('font-size', calcFontSize + 'rem');

        }

    });
}

function ds81_set_min_fill_height(){
    $('.min-height-fill').css('height', '0px');
    if($('body').outerHeight() < winDim().height)
    {
        // var minFillHeight = winDim().height - $('footer').outerHeight(true) - $('header').outerHeight(true);
        var minFillHeight = winDim().height - $('main').outerHeight(true) - $('header').outerHeight(true) - $('footer').outerHeight(true);
        if($('.bottom-sponsor-block').length > 0)
        {
            minFillHeight -=  $('.bottom-sponsor-block').outerHeight(true)
        }
        $('.min-height-fill').css('height', minFillHeight + 'px');
    }
}

function getBreakPointName() {
    if(typeof breakpoint === 'undefined')
    {
        return 'lg';
    }
    return breakpoint.substr(0,2);
}

function isWithinBreakpoint(checkAgainst)
{
    return getBreakPointName() === checkAgainst.toLowerCase();
}


function dsSetImageHeights()
{
    // Adjust images with height reference:
    $('[data-adjust-image-height-ref]').each(function () {
        var $root = $(this).parent();
        if(typeof $(this).data('adjust-image-height-root') !== "undefined")
        {
            $root = $(this).closest($(this).data('adjust-image-height-root'));
        }
        var $ref = $root.find('[data-height-for]').first();
        if($ref.length > 0)
        {
            $(this).css('height', $ref.innerHeight() + 'px');
        }

    });

    // Set image heights accoring to breakpoint (supersedes height reference);
    $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'visible');
    var bb = getBreakPointName();
    switch(bb)
    {
        case('xs'):
            $('[data-i-height-xs]').each(function () {
                $(this).css('height', $(this).data('i-height-xs') + 'px');
            });
            break;
        case('sm'):
            $('[data-i-height-sm]').each(function () {
                $(this).css('height', $(this).data('i-height-sm') + 'px');
            });
            break;
        case('md'):
            $('[data-i-height-md]').each(function () {
                $(this).css('height', $(this).data('i-height-md') + 'px');
            });
            break;
        case('lg'):
            $('[data-i-height-lg]').each(function () {
                $(this).css('height', $(this).data('i-height-lg') + 'px');
            });
            break;
        default:
            $('[data-i-height-xl]').each(function () {
                $(this).css('height', $(this).data('i-height-xl') + 'px');
            });
            break;
    }
    // $('[data-i-height-xs],[data-i-height-sm],[data-i-height-md],[data-i-height-lg],[data-i-height-xl]').css('overflow-y', 'hidden');

    // Activate focuspoint on images:
    $('.focuspoint').focusPoint();

    // Trigger re-init on image load:
    $('img').each(function () {
        if(!$(this).hasClass('load-init') && !$(this).hasClass('loaded'))
        {
            $(this).addClass('load-init');
            $(this).one("load", function () {
                if(this.complete) {
                    $(this).addClass('loaded').removeClass('load-init');
                    dsSetImageHeights();
                }
            });
        }
    });

}



function ds81_init_videobox($target) {

    $target.on('click.ds_video_init', function (e) {

        $(this).off('click.ds_video_init');

        // Check cookie consent:
        if($(this).data('videotype') === 'file' || dscookiebar_has_consent($(this).data('videotype')))
        {
            ds81_init_videobox_continue($(e.currentTarget));
        }
        else
        {
            $(this).find('.cookie-agreement').removeClass('d-none').addClass('d-flex');
            $(this).find('.cookie-agreement .btn-add-agreement').on('click.dscookieagreement', function (){
                var $myRoot = $(this).closest('.videobox');
                dscookiebar_add_consent($myRoot.data('videotype'), function (_$myRoot){
                    _$myRoot.find('.cookie-agreement').remove();
                    ds81_init_videobox_continue(_$myRoot);
                }, $myRoot);
            });
        }

    });


}

function ds81_is_iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

var plyrRunningInstance;

function ds81_init_videobox_continue($target) {


    $target.addClass('loading');
    var $root = $target.closest('.video-root').first();
    var tplHtml = $root.find('.video-embed').first().html();
    var tpl = _.template(tplHtml);
    var tplRendered = tpl({});

    $root.find('.videobox').append('<div class="videoembed"></div>');
    $root.find('.videobox .videoembed').html(tplRendered);
    var $plyrMain = $root.find('.videobox .videoembed .plyr_main').first();
    console.log('CONTINUE', $plyrMain.attr('id'));
    var p = new Plyr('#' + $plyrMain.attr('id'), {
        iconUrl: path_root + 'lib/plyr/plyr.svg',
        blankVideo: path_root + 'lib/plyr/blank.mp4',
        debug: false
    });

    // $plyrMain.css('opacity', 0);
    // $root.find('.loading-icon').removeClass('d-none');
    $root.find('.play-icon').addClass('d-none');
    p.on('error', function (e) {

        var $plyr = $(e.target);
        var $main = $plyr.closest('.videobox');
        $main.removeClass('loading');
        $main.find('.loading-icon').addClass('d-none');
        $main.find('.play-icon.d-none').removeClass('d-none');
        $main.find('.videoembed').remove();
        ds81_init_videobox($main);
        console.error('plyr error', e);


    });

    $plyrMain.css('opacity', 1);
    $root = $plyrMain.closest('.videocontainer');
    $root.find('.loading-icon').addClass('d-none');

    // if on ios: native player required. Show contents.
    if(ds81_is_iOS())
    {
        $plyrMain.css('opacity', 1);
        $root = $plyrMain.closest('.videocontainer');
        $root.find('.loading-icon').addClass('d-none');
    }

    p.on('ready', function (e) {
        var instance = e.detail.plyr;


        console.log("VIDEO READY FOR PLAYBACK", e, instance, instance.playing);

        var $plyr = $(e.target);
        var $main = $plyr.closest('.videobox');
        $main.removeClass('loading');


        instance.on('canplay', function (e) {
            console.log('canplay', e);
        });
        instance.on('statechange', function (e) {
            console.log('STATECHANGE', e);
        });
        instance.on('playing', function (e) {

            console.log("VIDEO PLAYING");

            $main.addClass('play-was-started');

            var instance = e.detail.plyr;

            if(plyrRunningInstance && !$(plyrRunningInstance.media).is($(instance.media)))
            {
                console.log('PAUSING OTHER INSTANCE', plyrRunningInstance);
                plyrRunningInstance.pause();
            }

            plyrRunningInstance = instance;
            console.log('UPDATING PLAYING INSTANCE', plyrRunningInstance);


            var $plyr = $(e.target);
            $plyr.css('opacity', 1);
            $root = $plyr.closest('.videocontainer');
            $root.find('.loading-icon').addClass('d-none');
            var $container = $root.closest('.image-holder');
            var $pointer = $container.find('.pointer');
            $pointer.animate({
                opacity: 0,
                right: -100
            }, 800, function (){
                $(this).remove();
            })
        });


        // If NOT on iOs: Safari bugfix: Needs an extra kick.
        if(!ds81_is_iOS())
        {
            setTimeout(function (){
                if(!instance.playing && !$main.hasClass('play-was-started'))
                {
                    $main.addClass('play-was-started');
                    instance.play();
                }
                console.log("VIDEO NOW PLAYING (1)");
            }, 1000);
            setTimeout(function (){
                if(!instance.playing && !$main.hasClass('play-was-started'))
                {
                    $main.addClass('play-was-started');
                    instance.play();
                }
                console.log("VIDEO NOW PLAYING (2)");
            }, 2000);
            setTimeout(function (){
                if(!instance.playing && !$main.hasClass('play-was-started'))
                {
                    $main.addClass('play-was-started');
                    instance.play();
                }
                console.log("VIDEO NOW PLAYING (3)");
            }, 3000);
            if(!instance.playing)
            {
                $main.addClass('play-was-started');
                console.log("VIDEO NOW PLAYING (0)");
                instance.play();
            }
        } else{

            console.log("VIDEO PLAYING?", instance.playing);
        }


    });
}


function dscookiebar_handle_consent_change()
{
    // Update onpage instances:
    var $targets = $('body').find('.dscookiebar,.consent-item-list-onpage');
    $targets.find('input[type="checkbox"]').each(function () {

        // Set current state:
        if (dscookiebar_has_consent($(this).attr('name'))) {
            $(this).prop('checked', true);
        }
        else
        {
            $(this).prop('checked', false);
        }

    });


    // Google Analytics:
    if(dscookiebar_has_consent('ga'))
    {
        dscookiebar_load_and_fire_tracking_calls();
    }

    // Show/hide consent elements:
    $('[data-consent-required]').each(function (){

        // Get consent id:
        var myConsent = $(this).data('consent-required');

        $('.addconsent').on('click', function (e){
            e.preventDefault();
            dscookiebar_add_consent($(this).data('addconsent'));
        });

        if(dscookiebar_has_consent(myConsent))
        {
            // Manage show/hide:
            $(this).find('[data-ifconsent="yes"]').removeClass('d-none');
            $(this).find('[data-ifconsent="no"]').addClass('d-none');

            // Parse template:
            var pluginTemplate = $(this).find('[data-ifconsent="yes"] .plugin-template').first();
            if(pluginTemplate.length > 0)
            {
                // console.log('IN');
                var tpl = _.template(pluginTemplate.html());
                var tplRendered = tpl({});
                $(this).find('[data-ifconsent="yes"]').html(tplRendered);
            }
        }
        else
        {
            // Manage show/hide:
            $(this).find('[data-ifconsent="yes"]').addClass('d-none');
            $(this).find('[data-ifconsent="no"]').removeClass('d-none');
        }

    });

}

function dscookiebar_add_consent(addConsent, callback){

    var $bar = $('body .dscookiebar').first();

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');

    // Consent found?
    var newConsentFound = false;

    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        if(cookieIDs[i] == addConsent)
        {
            newConsentFound = true;
        }
        var setValue = 0;
        if(dscookiebar_has_consent(cookieIDs[i]) || cookieIDs[i] == addConsent)
        {
            setValue = 1;
        }
        cookieStringList.push(cookieIDs[i] + '=' + setValue);
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');

    // Callback?
    if(typeof callback == 'function')
    {
        // console.log('FN A', arguments);
        callback(arguments[2], arguments[3], arguments[4], arguments[5]);
    }

}


function dscookiebar_onpage_check_status() {

    // Check current status and enable save button if onpage settings do not match current consent:
    if($('#dscookiebar_onpage_consent').length > 0)
    {
        $('#dscookiebar_onpage_consent').each(function () {

            // Flag: All settings match current selection:
            var allMatch = true; // Assume true first.

            // Process checkboxes and check selection:
            $(this).find('input[type="checkbox"]').each(function () {

                // Check status:
                if(dscookiebar_has_consent($(this).attr('name')) != $(this).prop('checked'))
                {
                    // No match. Checkbox state does not equal current consent.
                    allMatch = false;
                }

            });

            // Set button status:
            $(this).find('.btn-save-consent-settings').prop('disabled', allMatch);
            if(allMatch)
            {
                $(this).find('.message-all-up-to-date').removeClass('d-none');
            }
            else
            {
                $(this).find('.message-all-up-to-date').addClass('d-none');
            }

        });
    }

}

function dscookiebar_has_consent(which) {
    var allConsents = dscookiebar_get_consents();
    return jQuery.inArray( which, allConsents) >= 0;
}

function dscookiebar_get_consents() {

    // Consent version id:
    var cookieVersion = $('#dscookiebar_consent').first().data('version');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Prepeare return array:
    var ret = [];

    // Read cookie:
    if(typeof Cookies.get('dscookieconsent_version', {path: cookiePath}) !== "undefined" && Cookies.get('dscookieconsent_version', {path: cookiePath}) == cookieVersion)
    {
        // Get cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
        var consentData = Cookies.get('dscookieconsent', { path: cookiePath });

        // Data from cookie obtained?
        if(typeof consentData !== "undefined")
        {
            var consentArray = deparam(consentData);
            for(var i in consentArray)
            {
                if(parseInt(consentArray[i]) > 0)
                {
                    ret.push(i);
                }
            }
        }

    }

    // Return given consents:
    return ret;

}

function dscookiebar_hide_bar($bar) {

    $('html').removeClass('dscookiebar-show-cover');

    $bar.find('.inner').animate({
        // height: 0,
        opacity: 0,
    }, {
        duration: 300,
        complete: function () {
            $(this).closest('.dscookiebar').hide();
        }
        // ,
        // step: function (now, tween) {
        // $(this).parent().css('height', $(this).outerHeight() + 'px');
        // }
    });

}

function dscookiebar_accept_all_handler(e) {

    if(typeof e !== "undefined")
    {
        e.preventDefault();
    }

    // Get references:
    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    dscookiebar_hide_bar($bar);

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');
    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        cookieStringList.push(cookieIDs[i] + '=1');
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');

}

function dscookiebar_show_select_consent_content(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    $bar.find('.content-select-consent').removeClass('d-none');
    $bar.find('.content-default').addClass('d-none');
    $('html').addClass('dscookiebar-show-cover');

}

function dscookiebar_show_default_content(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $bar = $button.closest('.dscookiebar');

    $bar.find('.content-select-consent').addClass('d-none');
    $bar.find('.content-default').removeClass('d-none');
    $('html').removeClass('dscookiebar-show-cover');
}

function dscookiebar_save_consent(e) {

    e.preventDefault();

    var $button = $(e.currentTarget);
    var $form = $button.closest('form');

    // Find bar to obtain data:
    var $bar = $('#dscookiebar_consent').first();

    var checked = [];
    $form.find('input.consent-check:checked').each(function () {
        checked.push($(this).attr('name'));
    });

    dscookiebar_hide_bar($bar);

    // Consent version id:
    var cookieVersion = $bar.data('version');

    // All IDs:
    var cookieIDs = $bar.data('cookies-list');
    var mandatoryIDs = $bar.data('mandatory-list');
    var cookieStringList = [];
    for(var i in cookieIDs)
    {
        var myId = cookieIDs[i];
        var myState = 0;

        // Selected or mandatory?
        if(jQuery.inArray( myId, checked) >= 0 || jQuery.inArray( myId, mandatoryIDs) >= 0)
        {
            myState = 1;
        }

        cookieStringList.push(myId + '=' + myState);
    }
    var cookieString = cookieStringList.join('&');

    // Cookie path:
    var cookiePath = typeof path_root !== "undefined" ? path_root : '';

    // Set cookie using js-cookie helper (https://github.com/js-cookie/js-cookie)
    Cookies.set('dscookieconsent', cookieString, { expires: 365, path: cookiePath });
    Cookies.set('dscookieconsent_version', cookieVersion, { expires: 365, path: cookiePath });

    // Trigger event:
    $(window).trigger('dscookiebar_consentchange');
}

var dscookiebar_current_page_tracked = false;
var dsGtag;
function dscookiebar_load_and_fire_tracking_calls()
{

    // No tracking if backend user is logged in:
    if(typeof dp_prevent_count !== "undefined") return false;

    // dp_ga_uid set and has content?
    if(typeof dp_ga_uid === 'undefined' || dp_ga_uid.length < 1) return false;

    if(dscookiebar_current_page_tracked === false)
    {
        dscookiebar_current_page_tracked = true;
        dscookiebar_LoadJsCssFile('https://www.googletagmanager.com/gtag/js?id=' + dp_ga_uid, 'js', function () {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', dp_ga_uid, { 'anonymize_ip': true });
            dsGtag = gtag;
        });
    }
}


// File loader:
function dscookiebar_LoadJsCssFile(filename, filetype, callback){
    if (filetype=="js"){ //if filename is a external JavaScript file
        var fileref=document.createElement('script')
        fileref.setAttribute("type","text/javascript")
        fileref.setAttribute("src", filename)
    }
    else if (filetype=="css"){ //if filename is an external CSS file
        var fileref=document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
    }

    if(typeof callback === "function")
    {
        fileref.onload = callback;
    }

    if (typeof fileref!="undefined")
        document.getElementsByTagName("head")[0].appendChild(fileref)
}

function FECore(){

    var hashKv = [];

    this.init = function () {

        // Bind hash change:
        $(window).on('hashchange', this.handleHashChange);
        this.handleHashChange();

    }

    this.handleHashChange = function (e) {

        // Get hash:
        var hash = decodeURIComponent(location.hash.substring(location.hash.indexOf('#') + 1));

        var hashParams = hash.split('|');

        for(var i in hashParams)
        {
            var parts = hashParams[i].split('=');
            hashKv[parts[0]] = parts[1];
        }
    }

    this.setHashParam = function(k, v){
        hashKv[k] = v;
        var blocks = [];
        for(var i in hashKv){
            if(i.length > 0)
                blocks.push(i + '=' + hashKv[i]);
        }
        location.hash = blocks.join('|');
    }

    this.getHashValue = function(k){
        return typeof hashKv[k] !== "undefined" ? hashKv[k] : null;
    }

    this.clearHashValue = function(k){
        delete hashKv[k];
        var blocks = [];
        for(var i in hashKv){
            if(i.length > 0)
                blocks.push(i + '=' + hashKv[i]);
        }
        if(blocks.length > 0)
        {
            location.hash = blocks.join('|');
        }
        else
        {
            history.replaceState(null, null, ' ');
        }
    }

    this.updatePageFragment = function(fragmentId, value, setAsHtml)
    {
        $('[data-fragmentid="' + fragmentId + '"]').each(function () {

            if($(this).is('input')){
                $(this).val(value);
            }
            else
            {
                if(typeof setAsHtml !== "undefined" && setAsHtml === true)
                {
                    $(this).html(value);
                }
                else
                {
                    $(this).text(value);
                }
            }

        });
    }

    this.setUserPreference = function(preferenceKey, value)
    {

        // Build register:
        var prefRegister = {
            key: preferenceKey,
            value: value
        };

        $.ajax(
            app_base_path + 'me/preferences/set/', // Service-URL
            {
                type: 'post',
                data: {
                    preferenceData: prefRegister
                },
                dataType: 'json',
                success: function (data, textStatus, jqXHR) { // AJAX was ok

                },
                error: function (jqXHR, textStatus, errorThrown) {

                }

            }
        );


    }

}
