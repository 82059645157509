function rteInit($target){
    if(typeof rteConfig === "undefined") return false;
    var rteConfigJSON = JSON.parse(rteConfig);
    for(var i in rteConfigJSON)
    {
        var cfg = rteConfigJSON[i];
        $target.find('.' + i).each(function () {
            summernoteDefaultInit($(this), cfg);
        });
    }
}

function summernoteDefaultInit($target, configExtension) {

    // Prepare and extend config if set:
    var config = {
        disableDragAndDrop: true,
        toolbar: [
            // [groupName, [list of button]]
            ['style', ['style']],
            ['font', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['para', ['ul', 'ol']],
            ['align', ['cms-justifyLeft','cms-justifyCenter','cms-justifyRight']],
            ['view', ['codeview', 'cms-link']],
        ],
        cleaner:{
            // CUSTOM CLEANER IN USE!! See /javascript/summernote/custom-summernote-cleaner.js
            allowed: '<br><br/><sup><sub><i><b><u><ol><ul><li><p><h1><h2><h3><h4><h5><h6>'
        },
        cmsLink: {

        },
        popover: {
            image: [],
            link: ['cmseditor-popover-link'],
            air: []
        },

    };

    // Max chars enabled?
    if(typeof $target.data('max-chars') !== "undefined" && parseInt($target.data('max-chars')) > 0)
    {
        var maxAllowedChars = parseInt($target.data('max-chars'));
        $.extend(config, {
            charLimit: maxAllowedChars,
            callbacks: {
                onKeydown: function (e) {
                    var t = e.currentTarget.innerText;
                    if (t.length >= maxAllowedChars) {
                        //delete keys, arrow keys, copy, cut, select all
                        if (e.keyCode != 8 && !(e.keyCode >=37 && e.keyCode <=40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
                            e.preventDefault();
                    }
                },
                onKeyup: function (e) {
                    var t = e.currentTarget.innerText;
                    if(t.trim().length < 1)
                    {
                        t = t.trim();
                    }
                    if(t == '')
                    {
                        $(this).summernote('reset');
                        $(this).summernote('restoreRange');
                        console.log('editor reset', $(this).summernote('isEmpty'));
                    }
                    var charOut = t.length;
                    var charOutMax = parseInt($target.closest('.form-group').find('.maxchars-info').data('max-chars'));
                    if(charOut > charOutMax)
                    {
                        charOut = charOutMax;
                    }
                    $target.closest('.form-group').find('.maxchars-info .curchars').text(charOut);
                },
                onInit: function() {
                    var t = $($(this).summernote('code')).text();
                    if(t.trim().length < 1)
                    {
                        t = t.trim();
                    }
                    var charOut = t.length;
                    var charOutMax = parseInt($(this).closest('.form-group').closest('.form-group').find('.maxchars-info').data('max-chars'));
                    if(charOut > charOutMax)
                    {
                        charOut = charOutMax;
                    }
                    $(this).closest('.form-group').find('.maxchars-info .curchars').text(charOut);
                },
                onChange: function (contents, $editable) {
                    var t = $(contents).text();
                    if(t.trim().length < 1)
                    {
                        t = t.trim();
                    }
                    var charOut = t.length;
                    var charOutMax = parseInt($(this).closest('.form-group').closest('.form-group').find('.maxchars-info').data('max-chars'));
                    if(charOut > charOutMax)
                    {
                        charOut = charOutMax;
                    }
                    $target.closest('.form-group').find('.maxchars-info .curchars').text(charOut);
                }
            }
        });

        var postfixString = '';
        if(typeof $target.data('max-chars-postfix') !== "undefined")
        {
            postfixString = $target.data('max-chars-postfix');
        }

        $target.after('<div class="maxchars-info"><span class="curchars">0</span>/' + parseInt($target.data('max-chars')) + postfixString + '</div>');
    }



    if(typeof configExtension !== "undefined")
    {
        $.extend(config, configExtension);
    }

    // Initialize i18n if locale is known:
    switch (locale) {
        case('de_DE'):
            config.lang = locale.replace('_', '-');
            break;
    }


    // Initialize summernote with config:
    $target.summernote(config).on('summernote.change', function(customEvent, contents, $editable) {
        // Revalidate the content when its value is changed by Summernote
        $form = $(this).closest('form');
        if(typeof $form.data('form-fv') !== "undefined")
        {
            var fv = $form.data('form-fv');
            var fieldName = $(this).attr('name');
            if(typeof fv.getFields()[fieldName] !== "undefined")
            {
                fv.revalidateField(fieldName);
            }
        }

    });
}

export default rteInit;
