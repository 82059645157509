
import _ from "underscore";

(function( $ ) {

    $.fn.addTimedMessage = function( messageType, message, duration, callback ) {

        // Message template:
        var messageTemplateHtml = '<div class="alert alert-<%= messageType %> timed-alert-message hidden" data-messageid="<%= messageId %>">' +
                '<i class="mdi mdi-alert-circle-outline"></i> <span class="timedMessageContent"><%= message %></span>' +
                '<div class="progress"><div class="progress-bar bg-<%= messageType %>" role="progressbar" style="width: 100%" aria-valuemin="0" aria-valuemax="100"></div></div>' +
                '</div>';

        // Templatize with underscore template engine:
        var messageTemplate = _.template(messageTemplateHtml);

        // Set messageType to default if not suitable:
        if(messageType != 'info' && messageType != 'success' && messageType != 'danger' && messageType != 'warning')
        {
            messageType = 'default';
        }
        // set duration:
        if(!duration && duration != 0)
        {
            duration = 4000;
        }

        // determine message id:
        var messageId = $(this).find('.timed-alert-message').length;

        // Fill template:
        var messageHtml = messageTemplate({
            messageType: messageType,
            message: message,
            messageId: messageId
        });

        // Add HTML
        $(this).append(messageHtml);

        // Get references:
        var $currentMessage = $(this).find('.timed-alert-message[data-messageid="' + messageId + '"]');
        var $messageProgress = $currentMessage.find('.progress-bar').first();

        $currentMessage.removeClass('hidden').addClass('animated fadeIn');

        // Show timer if duration is > 0 (use 0 to disable timeout):
        if(duration > 0)
        {
            $currentMessage.unbind('mouseover').mouseover(function(){
                var $messageProgress = $(this).find('.progress-bar').first();
                $messageProgress.pause();
            });

            $currentMessage.unbind('mouseout').mouseout(function(){
                var $messageProgress = $(this).find('.progress-bar').first();
                $messageProgress.resume();
            });

            $messageProgress.animate({
                width: 0
            }, duration, 'linear', function(){

                $currentMessage.addClass('fadeOut').delay(600).animate({
                    opacity: 0,
                    height: 0
                }, 300, function(){
                    $(this).remove();
                    if (typeof callback === 'function') { // make sure the callback is a function
                        callback.call(this); // brings the scope to the callback
                    }
                });
            });

        }
        else
        {
            // Hide progressbar on non-timeout messages:
            $messageProgress.hide();
        }


        // Return this to allow chaining:
        return this;

    };

}( jQuery ));
